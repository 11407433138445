import React, { useState } from 'react';
import ErrorModal from '@ui-library/modal/error';
import ErrorMessageView from '@components/sources/visualizer/mappingScreens/errors/errorMessageView';
import get from 'lodash/get';
import classes from './common.module.scss';
import { Link1 } from '@ui-library/typography';
import { Alert } from '@ui-library/alerts';

export interface ErrorAssetProps {
  errMessage?: string;
  reloadMessage?: string;
  errorModalContent?: string;
}

export interface ErrorProps {
  type: string;
  errorAssets: ErrorAssetProps;
}

interface ErrorsViewProp {
  errors: Record<string, ErrorAssetProps>;
}

export const fetchErrorAssets = (type: string, errMsg?: string, reloadButtonText?: string) => {
  const errMessage = `Couldn't fetch ${type}`;
  const reloadMessage = `reload ${reloadButtonText || type} to resolve this`;
  const error: ErrorProps = {
    type,
    errorAssets: errMsg ? { errMessage, reloadMessage, errorModalContent: errMsg } : {},
  };
  return error;
};

export const ErrorsView: React.FC<ErrorsViewProp> = ({ errors }: ErrorsViewProp) => {
  const [errorModal, setErrorModal] = useState<ErrorAssetProps>({});

  const error = Object.keys(errors)
    .map((type: string) => ({
      type,
      ...get(errors, type, { errMessage: '', reloadMessage: '', errorModalContent: '' }),
    }))
    .find((curr) => curr.errMessage);

  return (
    <div className={classes.error_view_container} data-testid="error">
      {errorModal.errMessage && errorModal.errorModalContent && (
        <ErrorModal
          subHeader=""
          header={errorModal.errMessage}
          view={<ErrorMessageView errorMessage={errorModal.errorModalContent} />}
          onClose={() => setErrorModal({})}
          showBackButton={false}
          clipboardContent={errorModal.errMessage + errorModal.errorModalContent}
        />
      )}

      {error && (
        <Alert
          className="m-b-sm"
          type="error"
          description={
            <div>
              {error.errMessage}
              {', '}
              click{' '}
              <Link1
                className={classes.error_button}
                onClick={() => {
                  setErrorModal(error);
                }}
              >
                here
              </Link1>
              {' to check error.'}
              {error.reloadMessage ? ` Try ${error.reloadMessage}` : ''}.
            </div>
          }
        />
      )}
    </div>
  );
};
