import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

import { IDestinationStore } from '@stores/destination';
import { DestinationConfig } from '@stores/destination/index';
import { Source } from '@stores/source/types';

import { areAllSourcesInDeviceMode } from '../utils';
import { getSourceTypeForConnectionMode } from '../configurationV2/formComponents/util';

export const ALL_SOURCES = { value: 'all', label: 'All sources' };

const map: Record<number, string> = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  410: '410 Gone',
  422: 'Unprocessable Entity',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

export const getErrorMessage = (statusCode: number) => map[statusCode] || statusCode;

export const truncate = (event: unknown) => {
  const str = isString(event) ? event : JSON.stringify(event);
  if (str.length > 30) {
    return `${str.substring(0, 30)}...`;
  }
  return str;
};

type ConnectionMode = 'cloud' | 'device' | 'hybrid';

interface ConnectionModes {
  overall: 'allDevice' | 'mixed' | 'allCloud';
  sources: Record<string, ConnectionMode>;
}

export const calloutMessageTexts = {
  hybrid:
    'This connection is in hybrid mode. The graph only shows events that are passing in cloud mode.',
  device:
    'Event graph data is unavailable since this connection is in device mode. The graph is only available in cloud mode.',
  allDevice:
    'Event graph data is unavailable since all connections are in device mode. The graph is only available in cloud mode.',
  mixed:
    'Few connections are in hybrid or device mode. The graph only shows events that are passing in cloud mode.',
  cloud: '',
  allCloud: '',
};

export const getConnectionMode = (sourceId: string, connectionModes: ConnectionModes) => {
  if (sourceId === ALL_SOURCES.value) {
    return connectionModes.overall;
  }
  return connectionModes.sources[sourceId];
};

const getAllConnectionModes = (
  config: DestinationConfig,
  sources: Source[],
): Record<string, ConnectionMode> => {
  const { connectionMode } = config;

  const allConnectionModes = sources.reduce(
    (acc, source) => {
      const sourceType = getSourceTypeForConnectionMode(source.sourceDef);
      const key = source.id;
      if (isObject(connectionMode) && connectionMode?.[sourceType]) {
        acc[key] = connectionMode[sourceType] as ConnectionMode;
      } else {
        acc[key] = (config?.[`${sourceType}-connectionMode`] as ConnectionMode) || 'cloud';
      }
      return acc;
    },
    {} as Record<string, ConnectionMode>,
  );

  return allConnectionModes;
};

export const getSourcesConnectionMode = (destination: IDestinationStore): ConnectionModes => {
  const { config, sources } = destination;
  const allConnectionModes = getAllConnectionModes(config, sources);
  const isAllInDeviceMode = areAllSourcesInDeviceMode(config, sources);
  const isAllInCloudMode = Object.values(allConnectionModes).every((mode) => mode === 'cloud');

  let overallMode: ConnectionModes['overall'] = 'mixed';
  if (isAllInDeviceMode) {
    overallMode = 'allDevice';
  } else if (isAllInCloudMode) {
    overallMode = 'allCloud';
  }

  const connectionModes: ConnectionModes = {
    overall: overallMode,
    sources: allConnectionModes,
  };

  return connectionModes;
};
