import { experiment, flagPrefix } from '@lib/experiment';
import { Variant } from '@amplitude/experiment-js-client';

/**
 * Example destination experiment payload
 *
 * ```json
 *
 * { "FB_CUSTOM_AUDIENCE": true, "GOOGLE_ADS": true}
 *
 * ```
 */

export enum DestinationExperimentKeys {
  'vdmNext' = 'vdm-next',
}

// ts-prune-ignore-next
export const destinationExperimentFallbackValue = {
  [DestinationExperimentKeys.vdmNext]: {
    fallback: {
      variants: {
        on: true,
      },
      payload: {},
    },
  },
};

export const destinationExperimentKeys = Object.values(DestinationExperimentKeys);

type DestinationExperimentVariant = Variant & {
  payload: { [key: string]: boolean };
};

export const isFeatureEnabledForDestination = (
  destinationType: string,
  flag: DestinationExperimentKeys,
) => {
  const { fallback } = destinationExperimentFallbackValue[flag];
  const variant = (experiment.getVariant(flag, fallback) as DestinationExperimentVariant) ?? {
    payload: fallback,
  };

  return (variant?.payload ?? fallback)[destinationType] === true;
};

export const getAllDestinationFeatureFlags = (destinationType: string) => {
  const featureFlags: Record<string, boolean> = {};
  const variants = experiment.getAllVariants();

  if (!variants) {
    return featureFlags;
  }

  Object.keys(variants)
    .filter((variantKey) =>
      destinationExperimentKeys.includes(variantKey as DestinationExperimentKeys),
    )
    .forEach((variantKey) => {
      featureFlags[`${flagPrefix}${variantKey}`] = isFeatureEnabledForDestination(
        destinationType,
        variantKey as DestinationExperimentKeys,
      );
    });

  return featureFlags;
};
