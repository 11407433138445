import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const defaultDatetimeFormat = 'DD/MM/YYYY HH:mm:ss';

export const isValidDate = (date: string | number) => dayjs(date).isValid();

export const fromNow = (date: string) => dayjs(date).fromNow();

export const defaultFormat = (date: string) => dayjs(date).format(defaultDatetimeFormat);
