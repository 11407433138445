import {
  ExperimentClient,
  ExperimentUser,
  Variant,
  Variants,
} from '@amplitude/experiment-js-client';
import { AMPLITUDE_DEPLOYMENT_KEY, AMPLITUDE_PROXY_URL, LOCAL_FEATURE_FLAGS } from '@config';
import { DestinationExperimentKeys, destinationExperimentKeys } from '@lib/destinationExperiment';

type User = {
  id: string;
  isNewUser?: boolean;
  workspaceId: string;
  organizationId: string;
  planType?: string;
  environment?: string;
};

// List of feature flags
export enum FeatureFlagKeys {
  'deviceModeTransformations' = 'device-mode-transformations-web',
  'transformerSecrets' = 'transformer-secrets',
  'rs360EnableWht' = 'rs360-enable-wht',
  'latencyColumnOnHealthDashboard' = 'latency-column-on-health-dashboard',
  'latencyOnDestinationEventsTab' = 'latency-on-destination-events-tab',
  'delaysAlert' = 'delays-alert',
  'text2sql' = 'text-2-sql',
  'mtuUsage' = 'mtu-usage',
  'showWarehouseLatency' = 'show-warehouse-latency',
  'enableCohortAudience' = 'enable-cohort-audience',
  'enableDataCatalogCustomTypes' = 'enable-data-catalog-custom-types',
  'enabledDataCatalogTPWorkspaceCopy' = 'enable-data-catalog-tp-workspace-copy',
  'codegenUI' = 'codegen-ui',
  'enableCommandAI' = 'enable-commandai',
  'reportingDelay' = 'reporting-delay',
  'barGraphForWarehouseDest' = 'bar-graph-for-warehouse-dest',
  'enableDataCatalogComplexCustomTypes' = 'enable-data-catalog-complex-custom-types',
  'enableDataCatalogCustomTypesArrayItems' = 'enable-data-catalog-custom-types-array-items',
  'enableRetlCursorColumn' = 'enable-retl-cursor-column',
  'enableDestinationEventDetails' = 'obs-enable-destination-event-details',
}

const featureFlags = {
  [FeatureFlagKeys.deviceModeTransformations]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.transformerSecrets]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.rs360EnableWht]: {
    variants: {
      on: true,
    },
    default: LOCAL_FEATURE_FLAGS.RS360_ENABLE_WHT === 'true',
  },
  [FeatureFlagKeys.latencyColumnOnHealthDashboard]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.latencyOnDestinationEventsTab]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.delaysAlert]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.text2sql]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.mtuUsage]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.showWarehouseLatency]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableCohortAudience]: {
    variants: {
      on: true,
    },
    default: LOCAL_FEATURE_FLAGS.ENABLE_COHORT_AUDIENCE === 'true',
  },
  [FeatureFlagKeys.enableDataCatalogCustomTypes]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enabledDataCatalogTPWorkspaceCopy]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableCommandAI]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.reportingDelay]: {
    variants: {
      on: true,
    },
    payload: {
      delayInMinutes: 0,
    },
    default: false,
  },
  [FeatureFlagKeys.codegenUI]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.barGraphForWarehouseDest]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableDataCatalogComplexCustomTypes]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableDataCatalogCustomTypesArrayItems]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableRetlCursorColumn]: {
    variants: {
      on: true,
    },
    default: false,
  },
  [FeatureFlagKeys.enableDestinationEventDetails]: {
    variants: {
      on: true,
    },
    default: false,
  },
} as const;

export const flagPrefix = 'AMP_';

class AmplitudeExperiment {
  client: ExperimentClient | undefined;

  constructor() {
    if (AMPLITUDE_DEPLOYMENT_KEY) {
      import('@amplitude/experiment-js-client').then((res) => {
        this.client = res.Experiment.initialize(AMPLITUDE_DEPLOYMENT_KEY, {
          fetchOnStart: true,
          pollOnStart: false,
          serverUrl: AMPLITUDE_PROXY_URL ? `${AMPLITUDE_PROXY_URL}/api` : undefined,
          flagsServerUrl: AMPLITUDE_PROXY_URL ? `${AMPLITUDE_PROXY_URL}/flag` : undefined,
          fetchTimeoutMillis: 30000,
          retryFetchOnFailure: false,
        });
      });
    }
  }

  async start(payload: User) {
    await this.client?.start(this.getExperimentUser(payload));
  }

  private getExperimentUser(payload: User): ExperimentUser {
    const user_properties: Record<string, string | boolean> = {
      workspace_id: payload.workspaceId,
      organization_id: payload.organizationId,
    };

    if (payload.planType) {
      user_properties.plan_type = payload.planType;
    }

    if (payload.environment) {
      user_properties.environment = payload.environment;
    }

    if (payload.isNewUser) {
      user_properties.is_new_user = payload.isNewUser;
    }

    return {
      user_id: payload.id,
      user_properties,
    };
  }

  async fetch(user: User) {
    await this.client?.fetch(this.getExperimentUser(user));
  }

  reset() {
    this.client?.clear();
    this.client?.stop();
  }

  isFeatureEnabled(flag: FeatureFlagKeys): boolean {
    const variant = this.client?.variant(flag);

    if (variant && variant.value && variant.value in featureFlags[flag].variants) {
      return featureFlags[flag].variants[variant.value as 'on'];
    }

    return featureFlags[flag].default;
  }

  getAllVariants(): Variants | undefined {
    return this.client?.all();
  }

  getAllFeatureFlags(): Record<string | FeatureFlagKeys, boolean> {
    const featureFlags: Record<string | FeatureFlagKeys, boolean> = {};
    const variants = this.getAllVariants();

    if (!variants) {
      return featureFlags;
    }

    Object.keys(variants)
      .filter(
        (variantKey) =>
          !destinationExperimentKeys.includes(variantKey as DestinationExperimentKeys),
      )
      .forEach((variantKey) => {
        featureFlags[`${flagPrefix}${variantKey}`] = variants[variantKey].value === 'on';
      });

    return featureFlags;
  }

  getFeatureFlag<T extends keyof typeof featureFlags>(flag: T) {
    return this.client?.variant(flag) as (typeof featureFlags)[T];
  }

  getVariant<T extends string | Variant>(flag: string, fallback: T) {
    return this.client?.variant(flag, fallback);
  }
}

export const experiment = new AmplitudeExperiment();
