import React, { useEffect, Suspense, lazy } from 'react';
import { Skeleton } from 'antd';
import { requiresAuth, requiresMfaSession } from '@auth/appRouter/requiresAuth';
import { BrowserRouter as Router, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { disableCognito } from '@config';
import useStores from '@stores/useStores';
import { observer } from 'mobx-react';
import { RESOURCE_TYPES, SESSION_STORAGE_KEYS } from '@components/common/constants';
import { RudderCategory } from '@components/common/types';
import { apiAuthCaller } from '@services/apiCaller';
import { Path } from '@components/routes';
import MetaTag from './metaTag';
import { Toast } from './toast';

const ForgotPasswordPage = lazy(
  () => import(/* webpackChunkName: "auth.forgotPassword" */ '@auth/forgotPassword'),
);
const SSOPage = lazy(() => import(/* webpackChunkName: "auth.sso" */ '@auth/sso'));
const ESignupPage = lazy(
  () => import(/* webpackChunkName: "auth.esignup" */ '@auth/signup/esignup'),
);
const Home = lazy(() => import(/* webpackChunkName: "home" */ '@components/home/home'));
const AcceptInvite = lazy(
  () => import(/* webpackChunkName: "auth.acceptInvite" */ '@auth/acceptInvite'),
);
const LoginPage = lazy(() => import(/* webpackChunkName: "auth.login" */ '@auth/login/login'));
const BlendoSignupPage = lazy(
  () => import(/* webpackChunkName: "auth.blendoSignup" */ '@auth/blendo/blendoSignup'),
);
const MfaVerify = lazy(
  () => import(/* webpackChunkName: "auth.mfaVerify" */ '@auth/mfa/mfaVerify'),
);
const AddMfa = lazy(() => import(/* webpackChunkName: "auth.addMfa" */ '@auth/mfa/addMfa'));
const AddMfaLater = lazy(
  () => import(/* webpackChunkName: "auth.addMfaLater" */ '@auth/mfa/addMfaLater'),
);
const AddMfaSuccess = lazy(
  () => import(/* webpackChunkName: "auth.addMfaSuccess" */ '@auth/mfa/addMfaSuccess'),
);
const AddPhonePage = lazy(
  () => import(/* webpackChunkName: "auth.addPhonePage" */ '@auth/addPhonePage'),
);
const Signup = lazy(() => import(/* webpackChunkName: "auth.signup" */ '@auth/signup'));
const Security = lazy(() => import(/* webpackChunkName: "security" */ '@components/security'));
const WaitingAuthentication = lazy(
  () => import(/* webpackChunkName: "auth.WaitingAuthentication" */ './WaitingAuthentication'),
);
const SelectOrganization = lazy(
  () => import(/* webpackChunkName: "organizations" */ '@components/organizations'),
);

interface CallbackRouteParams {
  role: string;
  rudderCategory?: RudderCategory;
}

const AppRouter: React.FC = () => {
  const { userStore, sourceDefinitionsListStore, destinationDefsListStore } = useStores();
  const enableMFA = !disableCognito;

  const authCallback = (props: RouteComponentProps<CallbackRouteParams>) => {
    const oauthCallback = async (compProps: RouteComponentProps<CallbackRouteParams>) => {
      let oauthMetaKey = SESSION_STORAGE_KEYS.source;
      const isDest = compProps!.match.params.rudderCategory === RESOURCE_TYPES.DESTINATION;

      if (isDest) {
        oauthMetaKey = SESSION_STORAGE_KEYS.destination;
      }

      const { pathname } = compProps!.location;
      const OauthMetadata = sessionStorage.getItem(oauthMetaKey);

      let extraQueryParams = {
        t: window.btoa(userStore.token),
        workspaceId: userStore?.selectedWorkspaceId,
      };

      if (OauthMetadata) {
        try {
          const metadataFromStorage = JSON.parse(OauthMetadata);
          extraQueryParams = {
            ...metadataFromStorage,
            ...extraQueryParams,
          };
        } catch (err) {}
      }

      const urlData = await apiAuthCaller({ nonWorkspaceRoute: true }).get(
        `${pathname}${compProps!.location!.search}`,
        { params: extraQueryParams },
      );

      window.location.assign(urlData.data);
    };

    oauthCallback(props);

    return <WaitingAuthentication role={props!.match.params.role} />;
  };

  useEffect(() => {
    const definitionApiCalls = [
      sourceDefinitionsListStore.getAll(),
      destinationDefsListStore.getDestinationDefs(),
    ];

    Promise.all(definitionApiCalls);
  }, []);

  return (
    <Suspense fallback={<Skeleton active />}>
      <Toast />
      <Security />
      <Router>
        <MetaTag />
        <Switch>
          <Route path="/health">
            <h3>Hey There!!! The App is Healthy</h3>
          </Route>
          <Route path="/blendoSignup" exact component={BlendoSignupPage} />
          <Route path={Path.SIGNUP} exact component={disableCognito ? ESignupPage : Signup} />
          <Route path={Path.LOGIN} exact component={LoginPage} />
          <Route path="/esignup" exact component={ESignupPage} />
          <Route path="/elogin" exact component={LoginPage} />
          <Route path="/sso" exact component={SSOPage} />
          <Route path="/auth/:role/callback" exact component={authCallback} />
          <Route path="/auth/:rudderCategory/:role/callback" exact component={authCallback} />
          <Route path="/oauth/success/:id" exact component={WaitingAuthentication} />
          <Route path="/oauth/error/:message" exact component={WaitingAuthentication} />
          <Route path="/forgotPassword" exact component={ForgotPasswordPage} />
          <Route path="/acceptInvite" exact component={AcceptInvite} />
          {enableMFA && <Route exact path="/addmfa" component={requiresMfaSession(AddMfa)} />}
          {enableMFA && <Route exact path="/addmfalater" component={requiresAuth(AddMfaLater)} />}
          {enableMFA && (
            <Route exact path="/addmfasuccess" component={requiresMfaSession(AddMfaSuccess)} />
          )}
          {enableMFA && (
            <Route exact path="/addphone" component={requiresMfaSession(AddPhonePage)} />
          )}
          {enableMFA && (
            <Route exact path="/verifyphone" component={requiresMfaSession(MfaVerify)} />
          )}
          <Route path={Path.SELECT_ORGANIZATION} component={requiresAuth(SelectOrganization)} />
          <Route path="/" component={requiresAuth(Home)} />
        </Switch>
      </Router>
    </Suspense>
  );
};

/* eslint-disable import/no-default-export */
export default observer(AppRouter);
