import Axios, { AxiosInstance, CreateAxiosDefaults } from 'axios';
import axiosRetry, { IAxiosRetryConfig } from 'axios-retry';

import { Region } from '@components/common/constants';
import { isLocalhost } from '@components/common/util/util';
import { getBackendUrl } from '@services/utils';
import { RequestConfig } from '@utils/errorLogger';
import { stores } from '@stores/index';
import {
  bugsnagInterceptor,
  mfaInterceptor,
  responseTimeInterceptor,
  refreshTokenInterceptor,
  retrySuccessInterceptor,
} from './interceptors';

const DEFAULT_TIMEOUT = 60000;

const JSON_MIME_TYPE = 'application/json';

// ts-prune-ignore-next
export type CustomAxiosInstance = AxiosInstance & {
  ignore400: () => AxiosInstance;
};

// ts-prune-ignore-next
export const axiosInstanceMap = new Map<string, CustomAxiosInstance>();

type CreateAxiosOptions = {
  withMfaInterceptor?: boolean;
  withBugsnagInterceptor?: boolean;
  withRefreshTokenInterceptor?: boolean;
  withRetrySuccessInterceptor?: boolean;
};

// ts-prune-ignore-next
export const createAxios = (
  name: string,
  config: CreateAxiosDefaults,
  {
    withMfaInterceptor = true /* Attach MFA interceptor by default */,
    withBugsnagInterceptor = false,
    withRefreshTokenInterceptor = false,
    withRetrySuccessInterceptor = false,
  }: CreateAxiosOptions = {},
) => {
  // Return existing instance  without reconfiguring interceptors
  if (axiosInstanceMap.has(name)) {
    return axiosInstanceMap.get(name) as CustomAxiosInstance;
  }

  // Else create it and attach interceptors
  const axiosInstance = Axios.create(config) as CustomAxiosInstance;

  axiosInstance.ignore400 = () => {
    axiosInstance.interceptors?.request.use((config) => {
      (config as RequestConfig).ignore400 = true;

      return config;
    });

    return axiosInstance;
  };

  responseTimeInterceptor(axiosInstance);

  if (withRefreshTokenInterceptor) {
    refreshTokenInterceptor(axiosInstance);
  }

  if (withMfaInterceptor) {
    mfaInterceptor(axiosInstance);
  }

  if (withBugsnagInterceptor) {
    bugsnagInterceptor(axiosInstance);
  }

  if (withRetrySuccessInterceptor) {
    retrySuccessInterceptor(axiosInstance);
  }

  // And save it to the map
  axiosInstanceMap.set(name, axiosInstance);

  return axiosInstance;
};

const apiCaller = () => {
  const axiosInstance = createAxios(
    'apiCaller',
    {
      baseURL: getBackendUrl(Region.US),
      timeout: DEFAULT_TIMEOUT,
      headers: { 'Content-Type': JSON_MIME_TYPE },
    },
    { withBugsnagInterceptor: true },
  );

  return axiosInstance;
};

const apiAuthCallerWithSkipMfaResponseInterceptor = (token: string) => {
  const baseURL = getBackendUrl(Region.US);

  const axiosInstance = createAxios(
    'skipMfa',
    {
      baseURL,
      timeout: DEFAULT_TIMEOUT,
      withCredentials: !isLocalhost(),
      headers: { 'Content-Type': JSON_MIME_TYPE },
    },
    { withMfaInterceptor: false, withRefreshTokenInterceptor: true },
  );

  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

  return axiosInstance;
};

const apiAuthCaller = ({
  idToken = stores.userStore.token,
  nonWorkspaceRoute = false,
  timeout = DEFAULT_TIMEOUT,
  region = stores.userStore.selectedWorkspaceRegion,
  retryOptions,
}: {
  idToken?: string;
  nonWorkspaceRoute?: boolean;
  timeout?: number;
  region?: Region;
  retryOptions?: IAxiosRetryConfig;
} = {}) => {
  const { selectedWorkspaceId } = stores.userStore;

  const axiosInstance = createAxios(
    retryOptions ? 'apiAuthCallerWithRetries' : 'apiAuthCaller',
    {
      headers: { 'Content-Type': JSON_MIME_TYPE },
      withCredentials: !isLocalhost(),
    },
    {
      withRefreshTokenInterceptor: true,
      withRetrySuccessInterceptor: retryOptions !== undefined,
    },
  );

  // Always use the latest retry options on the `apiAuthCallerWithRetries` axios
  // instance
  if (retryOptions) {
    axiosRetry(axiosInstance, retryOptions);
  }

  const baseURL = nonWorkspaceRoute
    ? getBackendUrl(region)
    : `${getBackendUrl(region)}/workspaces/${selectedWorkspaceId}/`;

  axiosInstance.defaults.baseURL = baseURL;
  axiosInstance.defaults.timeout = timeout;
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${idToken}`;

  return axiosInstance;
};

export { apiCaller, apiAuthCaller, apiAuthCallerWithSkipMfaResponseInterceptor };
