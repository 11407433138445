import { AxiosResponse, AxiosRequestConfig } from 'axios';
import { Region } from '@components/common/constants';
import { BACKEND_BASE_URL } from '@config';

export const getBackendUrl = (region: Region): string => BACKEND_BASE_URL[region];

export class RetryError<T> extends Error {
  public readonly response: AxiosResponse<ApiResponse<T>>;

  public readonly config: AxiosRequestConfig<ApiResponse<T>>;

  constructor(message: string, response: AxiosResponse<ApiResponse<T>>) {
    super(message);

    this.name = 'RetryError';
    this.response = response;
    this.config = response.config;
  }
}
