import { init } from 'commandbar';
import type { History } from 'history';
import { commandAIConfig } from '@config';
import { experiment, FeatureFlagKeys } from '@lib/experiment';

// ts-prune-ignore-next
export class CommandAI {
  private hasInitialized = false;

  private hasBooted = false;

  private get enabled() {
    return commandAIConfig.orgId && experiment.isFeatureEnabled(FeatureFlagKeys.enableCommandAI);
  }

  public init(history: History) {
    if (!this.enabled || this.hasInitialized) {
      return;
    }

    // Initialize CommandAI
    init(commandAIConfig.orgId);

    // Attach to router
    //
    const routerFunc = (newUrl: string) => history.push(newUrl);
    window.CommandBar.addRouter(routerFunc);

    this.hasInitialized = true;
  }

  public async boot(userId: string) {
    if (!this.hasInitialized || this.hasBooted) {
      return;
    }

    // Boot CommandAI for the current user
    await window.CommandBar.boot(userId);

    this.hasBooted = true;
  }

  public shutdown() {
    if (!this.hasInitialized) {
      return;
    }

    window.CommandBar.shutdown();
    this.hasBooted = false;
  }

  public async setMeta(meta: Record<string, unknown>) {
    if (!this.hasInitialized || !this.hasBooted) {
      return;
    }

    window.CommandBar.addMetadataBatch(meta);
  }
}

export const commandAI = new CommandAI();
